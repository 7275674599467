import React from 'react'
import FeaturedProperty from './FeautrePorperty'
import RecentProperty from './RecentProperty'

export default function AllProperty() {
  return (
    <>
    <FeaturedProperty />
    <RecentProperty />
    </>
  )
}
